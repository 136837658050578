<template>
  <div class="loader">
    <div class="content text-white">
      <div class="p-3"><img id="logo" :src="runtimeConfig.public.logo" alt="GOP Variete Theater"></div>
      <div v-if="typeof globalStore.isLoading !== 'boolean'" class="loadingText" v-html="$store.state.loading" />
      <i class="fa fa-circle-notch fa-spin" />
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from "~/stores/GlobalStore";

const runtimeConfig = useRuntimeConfig();
const globalStore = useGlobalStore();
</script>
